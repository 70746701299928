import React, {useEffect} from "react";
import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import {WsManager} from "PNH/services/Ws";
import moment from "moment";
import {Api} from "../../../../services/Api";

const CHANNEL_NAME = "Client::ActivitiesChannel"

export const ActivitiesFunctions = {

  create: (values, formik) => {
    Api.activities
        .create_from_template({ template_id: values.template_id, activity: values })
        .then((response) => {
          if (response.ok) {
            window.setNotifications([
              { type: "success", msg: "Zlecono dodawanie zajęć. Może to zająć kilka minut" },
            ]);
            // let url = `/moje-zajecia?notification_type=${type}&notification_msg=${data.status}`;
            // window.location.href = url
          } else {
            window.setNotifications([
              { type: "alert", msg: "Nie udało się zapisać zmian" },
            ]);
          }
        });
  },

  defaultValues: (props) => {
    const defaultValues = {
      id: "",
      template_id: props.templateID,
      expired_at: moment(props.expired_at),
      date_from: moment().format("YYYY-MM-DD"),
      date_to: moment().format("YYYY-MM-DD"),
      days: [],
      afterSave: () => {},
    };
    return { ...defaultValues, ...props.defaults };
  },
};

const FormWrapper = ({...props}) => {
  return <Form {...props}/>
}


export const ActivitiesFromTemplateForm = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...ActivitiesFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);
    if (!values.id || formikProps.props.copy) {
      ActivitiesFunctions.create(values, formikProps)
    } else {
      ActivitiesFunctions.update(values, formikProps)
    }
  },
})(FormWrapper);
